
@font-face {
  font-family: poppinsBold;
  src: url(assets/fonts/Poppins-Bold.ttf);
}


@font-face {
  font-family: poppinsSemiBold;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: mulish;
  src: url(assets/fonts/Mulish-VariableFont_wght.ttf);
}

:root {
  background-color: #ffffff !important;
}

#root {
  background-color: #ffffff !important;
}

body {
  background-color: #ffffff !important;
}

h1 {
  font-family: poppinsBold;
  font-size: 64px;
  line-height: 72px;
  font-weight: bold;
}

h2 {
  font-family: poppinsBold;
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
}

h4 {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

h5 {
  font-family: poppinsSemiBold;
  font-size: 20px;
  line-height: 48px;
}

.fscontainer {
  height: 100vh;
  background-color: #ffffff;
}

body {
  /*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'; */
  padding: 0rem;
  margin: 0 auto;
  background-color: white;
}

.page_container {
  background-color: white;
  display: block;
  align-items: center;
  justify-content: center;
}

.spinner {
  height: "100%";
  display: block;
  align-items: center;
  justify-content: center;
}

.preserveformatting {
  white-space: pre-wrap;
}

.rightjustify {
  text-align: right;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 5px;
  margin-right: 5px;
}

.modalcontainer {
  height: 200px;
  display: flex;
  justify-content: space-between;
  /* for debugging border: 2px solid red; */
}

.sidebyside {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sidebysidec {
  /* same as sidebyside, but centered */
  display: flex;
  align-items: center;
  justify-content: center;
}

.allthewaytotheleft {
  float: left;
  padding-left: 5px;
}

.allthewaytotheright {
  float: right;
  padding-right: 5px;
}

.sidebyside_left {
  text-align: left;
  margin-right: 2px;
}

.sidebyside_right {
  text-align: left;
  margin-left: 2px;
}

.left-modal-div {
  width: 100px;
  text-align: left;
  /* for debugging border: 2px solid blue; */
}

.right-modal-div {
  width: 75%;
  text-align: right;
  /* for debugging border: 2px solid blue; */
}

.centered {
  display: flex;
  justify-content: center;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image {
  display: block;
  max-width: 100%;
  height: auto;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.truncate-text {
  padding: 8px;
  display: flex;
  align-items: center;
  max-height: 120px;
  max-width: 90vw;
  /* border: 2px solid red; */ /* debugging */
  overflow: hidden; /* Hide the overflowing text */
  word-wrap: break-word; /* Prevent text from wrapping */
  line-height: 1.4;
}

.menu_grid_item {
  background-color: transparent;
  padding: 20px;
  text-align: center;
  /* border: 6px solid blue; */
  /* For debugging */
}

.textinput {
  border: 2px solid #ccc; /* Width, style, and color of the border */
  border-radius: 5px; /* Rounded corners for the border */
  padding: 10px; /* Add padding inside the input field */
  font-size: 16px; /* Set the font size of the text */
}

.stacked_button_container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-left: 10px solid transparent;
}

.menu_grid_container {
  /* for the CreateNew component - list of options for creating new recipe */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.browse-flex-container {
  display: flex;
}

.browse-flex-container > .left-column {
  flex-shrink: 0;
}

.browse-flex-container > .right-column {
  flex-grow: 1;
  flex-basis: 0%;
}

.homescreentextbox {
  position: absolute;
  top: 55%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust based on its own size */
  color: black; /* Ensure text is visible over the video */
  padding: 15px;
  font-weight: 700;
  border: 3px solid black;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  z-index: 2; /* Ensure text appears above the video */
}

@media (max-width: 1024px) {
  .browse-flex-container {
    /* narrow screens: Single column layout */
    flex-direction: column;
  }

  .filters.hide {
    display: none;
  }

  .filters.show {
    display: flex;
  }
}

@media (min-width: 1025px) {
  .toggle-filters-button {
    /* wide screens: hide 'toggle filters' widget */
    display: none;
  }

  .filters {
    /* wide screens: show filters */
    display: flex;
  }
}

/* Show filters when toggled on narrow screens */
.filters.show {
  display: flex;
}

.videocontainer {
  /* video on home screen. Only for wide-screen devices */
  width: 100%;
  height: 100%;
  border-radius: 24px;
  padding: 0 40px;
  position: absolute;
}

.video {
  /* video on home screen. Only for wide-screen devices */
  width: 100%;
  height: 100%;
  border-radius: 24px;
  object-fit: cover;
}

.videosubcontent {
  position: relative;
  z-index: 1;
  color: white;
  /* ... other styles */
}

@media screen and (max-width: 768px) {
  .videocontainer {
    padding: 0 24px;
  }
}

@media (min-width: 1001px) {
  .menu_grid_container {
    /* for the CreateNew component - list of options for creating new recipe */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

.hero-img {
  width: 100%;
  object-fit: cover;
  aspect-ratio: 1360 / 720;
  border-radius: 24px;
}

.hero-img-container {
  position: relative;
  width: 100%;
  padding: 0 40px;
}

.hero-img-overlay {
  width: calc(100% - 80px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
      0deg,
      rgba(36, 49, 62, 0.05),
      rgba(36, 49, 62, 0.05)
    ),
    linear-gradient(
      269.71deg,
      rgba(36, 49, 62, 0) 0.25%,
      rgba(36, 49, 62, 0.5) 99.76%
    );
  margin: 0 40px;
  border-radius: 24px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 40px;
}

.hero-main-text {
  max-width: 1280px;
  height: 144px;
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
  color: #ffffff;
}

.hero-subtext {
  font-family: mulish;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
}

.hero-btn {
  font-family: mulish;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  width: 211px;
  background-color: #ffffff;
  color: #24313e;
  border-radius: 100px;
  cursor: pointer;
}

.hero-btn-icon {
  height: 16.88px;
  width: 15px;
}

@font-face {
  font-family: poppinsBold;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppinsSemiBold;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: mulish;
  src: url(assets/fonts/Mulish-VariableFont_wght.ttf);
}

h1 {
  font-family: poppinsBold;
  font-size: 64px;
  line-height: 72px;
  font-weight: bold;
}

h2 {
  font-family: poppinsBold;
  font-size: 40px;
  line-height: 48px;
  font-weight: bold;
}

h5 {
  font-family: poppinsSemiBold;
  font-size: 20px;
  line-height: 48px;
}

.modal-dialog {
  margin: 0;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  position: fixed;
  max-width: 576px;
  height: fit-content;
  top: 50%;
  transform: translate(0, -50%);
  padding: 24px;
  gap: 24px;
  border-radius: 24px;
  background: #ffffff;
}

.modal-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 24px 0;
}

.modal-header-flex {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  gap: 24px;
}

.modal-header-text {
  font-family: poppinsSemiBold;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin: 0;
  color: #24313e;
}

.modal-body-text {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #6a727b;
}

.modal-body-img {
  height: 240px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.modal-header-x {
  cursor: pointer;
}

@media (max-width: 768px) {
  .modal-content {
    max-width: calc(100% - 24px);
    margin: 0 12px;
    box-sizing: border-box;
  }
}

/* LOADING PAGE */

.loading-page {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 40px;
  background: #f6f8fa;
  border-radius: 16px;
}

.loading-spinner {
  width: 80px !important;
  height: 80px !important;
}
