@font-face {
  font-family: poppinsBold;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppinsSemiBold;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: mulish;
  src: url(assets/fonts/Mulish-VariableFont_wght.ttf);
}

.background {
  position: relative;
  top: 40px;
  background-color: white;
  width: 100vw;
  height: calc(100vh - 80px);
}

.home-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 24px;
}

.home-img-container {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 40px;
}

.home-img-overlay {
  width: calc(100% - 80px);
  height: 100%;
  position: absolute;
  top: 0;
  left: 40;
  background: linear-gradient(
      0deg,
      rgba(36, 49, 62, 0.05),
      rgba(36, 49, 62, 0.05)
    ),
    linear-gradient(
      269.71deg,
      rgba(36, 49, 62, 0) 0.25%,
      rgba(36, 49, 62, 0.5) 99.76%
    ),
    linear-gradient(
      180deg,
      rgba(36, 49, 62, 0) 47.92%,
      rgba(36, 49, 62, 0.4) 100%
    );
  border-radius: 24px;
}

.home-content {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.home-img {
  position: absolute;
  object-fit: cover;
  object-position: right top;
  top: 0px;

  width: calc(100% - 48px);
  border-radius: 24px;
}

.home-main-text {
  max-width: 1280px;

  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
  color: #ffffff;
}

.home-subtext {
  font-family: mulish;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
}

.home-btn {
  font-family: mulish;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  width: 211px;
  background-color: #ffffff;
  color: #24313e;
  border-radius: 100px;
  cursor: pointer;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.home-btn-icon {
  height: 16.88px;
  width: 15px;
}

@media screen and (max-width: 1024px) {
  .home-content {
    gap: 24px;
  }

  .home-main-text {
    font-family: PoppinsSemiBold;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin: 0;
    height: auto;
  }

  .home-subtext {
    font-family: Mulish;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .home-img-container {
    padding: 24px !important;
  }

  .home-img-overlay {
    left: 24;
    width: calc(100% - 48px) !important;
  }

  .background {
    top: 24px;
    width: 100vw;
    height: calc(100vh - 48px);
  }

  .home-content {
    padding: 24px;
    width: calc(100% - 48px);
  }

  .home-main-text {
    font-family: PoppinsSemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .home-subtext {
    font-family: Mulish;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 24px;
}

.hero-img-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
      0deg,
      rgba(36, 49, 62, 0.05),
      rgba(36, 49, 62, 0.05)
    ),
    linear-gradient(
      269.71deg,
      rgba(36, 49, 62, 0) 0.25%,
      rgba(36, 49, 62, 0.5) 99.76%
    ),
    linear-gradient(
      180deg,
      rgba(36, 49, 62, 0) 47.92%,
      rgba(36, 49, 62, 0.4) 100%
    );

  border-radius: 24px;
}

.hero-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.hero-main-text {
  max-width: 1280px;
  height: 144px;
  font-size: 64px;
  font-weight: 700;
  line-height: 72px;
  color: #ffffff;
}

.hero-subtext {
  font-family: mulish;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  color: #ffffff;
}

.hero-btn {
  font-family: mulish;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 48px;
  width: 211px;
  background-color: #ffffff;
  color: #24313e;
  border-radius: 100px;
  cursor: pointer;
}

.hero-btn-icon {
  height: 16.88px;
  width: 15px;
}

.email-verification-page {
  height: 100vh;
  width: 100vw;
  padding: 40px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: #24313e;
}

.email-verification-logo-container {
  width: 100%;
  display: flex;
  position: absolute;
  z-index: 100;
  padding: 0 40px;
}

.email-verification-btn {
  background: #24313e;
  color: #ffffff;
  cursor: pointer;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 100px;
}

.email-verification-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 24px;
}

.email-verification-modal {
  max-width: 576px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px 24px;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid #000000;
  background: #ffffff;
  z-index: 100;
}

.email-verification-title {
  font-family: PoppinsSemiBold;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  padding: 0px 0px 24px 0px;
  border-bottom: 1px solid #e1e7ed;
  color: #24313e;
}

.email-verification-text {
  font-family: PoppinsSemiBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.email-verification-subtext {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .email-verification-page {
    padding: 24px;
  }

  .email-verification-logo-container {
    padding: 0 24px;
  }
}
