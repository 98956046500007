@font-face {
  font-family: "ITC Avant Garde Gothic";
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: url("//warehouse-theme-wood.myshopify.com/cdn/fonts/itc_avant_garde_gothic/itcavantgardegothic_n5.d00e150ea051c566a429f90893989d2d46fadcd2.woff2?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=8d38549e756858b21abbf5a0391f788db103b09591b3ae0d75f2c8810c627809")
      format("woff2"),
    url("//warehouse-theme-wood.myshopify.com/cdn/fonts/itc_avant_garde_gothic/itcavantgardegothic_n5.df93c60e35fc5d3decb7a37c17990330d551bd55.woff?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=3349cd262ac273ea7072ee7b3149b9da8e820d7471909eaf70a2f4243ffc317c")
      format("woff");
}

@font-face {
  font-family: "Basic Commercial";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_n4.161334af349df25a2cfd2ef229e3fe638b6471c6.woff2?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=4ee0a426d737fd6b00b5190d24c1e9bb04bc20b24c67ec8699182918a7a66553")
      format("woff2"),
    url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_n4.db1c9e0093cb75b784ac848dd289a1fa6d164d13.woff?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=8d7e119b138797592dd76d8ae71b587fe697edd012615c1c9059b2b5db21b200")
      format("woff");
}

@font-face {
  font-family: "ITC Avant Garde Gothic";
  font-weight: 500;
  font-style: oblique;
  font-display: swap;
  src: url("//warehouse-theme-wood.myshopify.com/cdn/fonts/itc_avant_garde_gothic/itcavantgardegothic_o5.521c8429f968f23469f13c5104980709a46dcbd7.woff2?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=d8aa21240756ff04b4685c5d5b0d7f1e224d41913a1195ab222d9be254acccaf")
      format("woff2"),
    url("//warehouse-theme-wood.myshopify.com/cdn/fonts/itc_avant_garde_gothic/itcavantgardegothic_o5.8b7b32cb43e0ad877f4ba0a6dd16dcc63afdc35e.woff?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=5712928454605762e8ef44b8467511e1a1cb4522c036ce474149ee8ddbf4e268")
      format("woff");
}

@font-face {
  font-family: "Basic Commercial";
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_n7.58fc15bd5b52101eea961dd8bc6882e199098b2d.woff2?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=6fd1e130dcf6bc7e495a39122426391f82491c901eafcbed14e3e1bae009317d")
      format("woff2"),
    url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_n7.4e66c0895d465cee42ac67c5439d380b23021fbd.woff?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=f4cce438d896cbafee3013fd58390464e4a7f1e12cc573465eccf14ac7ec133f")
      format("woff");
}

@font-face {
  font-family: "Basic Commercial";
  font-weight: 400;
  font-style: italic;
  font-display: swap;
  src: url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_i4.1e3523a998a80ff2e6546b2cd001aec8a0856a1d.woff2?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=87c2bc6052439b128e4350ed118adb07f283f9c159a8e7a84ea655e6e36138af")
      format("woff2"),
    url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_i4.5a452364d8cbfe7624dcedc42ba47965004077e0.woff?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=fc3dc3a7e62f31ea1bf2432c45bcff72f426c49e90ef1ad42ee0edd9d1642d91")
      format("woff");
}

@font-face {
  font-family: "Basic Commercial";
  font-weight: 700;
  font-style: italic;
  font-display: swap;
  src: url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_i7.b744893e4d48b381d6dd2df580fe48cd51235018.woff2?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=15acea2ff19d7940e9e2bc9945ef9dda4912dbd29ae8711d6bd0daaeb69ef595")
      format("woff2"),
    url("//warehouse-theme-wood.myshopify.com/cdn/fonts/basic_commercial/basiccommercial_i7.e673ec03e93a1eb984e4975a041a3cf21f5e5582.woff?h1=d2FyZWhvdXNlLXRoZW1lLXdvb2QuYWNjb3VudC5teXNob3BpZnkuY29t&hmac=dc959a7facb569cc5d5f586a5938595f8fccc80f1c26f192bcab96994c2a7fd3")
      format("woff");
}

:root {
  --default-text-font-size: 15px;
  --base-text-font-size: 15px;
  --heading-font-family: "ITC Avant Garde Gothic", sans-serif;
  --heading-font-weight: 500;
  --heading-font-style: normal;
  --text-font-family: "Basic Commercial", sans-serif;
  --text-font-weight: 400;
  --text-font-style: normal;
  --text-font-bolder-weight: bolder;
  --text-link-decoration: underline;

  --text-color: #3a2a2f;
  --text-color-rgb: 58, 42, 47;
  --heading-color: #3a2a2f;
  --border-color: #e7e2de;
  --border-color-rgb: 231, 226, 222;
  --form-border-color: #dcd5cf;
  --accent-color: #ff6128;
  --accent-color-rgb: 255, 97, 40;
  --link-color: #ff6128;
  --link-color-hover: #da3a00;
  --background: #f7f4f2;
  --secondary-background: #ffffff;
  --secondary-background-rgb: 255, 255, 255;
  --accent-background: rgba(255, 97, 40, 0.08);

  --input-background: #ffffff;

  --error-color: #f71b1b;
  --error-background: rgba(247, 27, 27, 0.07);
  --success-color: #48b448;
  --success-background: rgba(72, 180, 72, 0.11);

  --primary-button-background: #ff6128;
  --primary-button-background-rgb: 255, 97, 40;
  --primary-button-text-color: #ffffff;
  --secondary-button-background: #3a2a2f;
  --secondary-button-background-rgb: 58, 42, 47;
  --secondary-button-text-color: #ffffff;

  --header-background: #3a2a2f;
  --header-text-color: #ffffff;
  --header-light-text-color: #e9e2dd;
  --header-border-color: rgba(233, 226, 221, 0.3);
  --header-accent-color: #ff6128;

  --footer-background-color: #f7f4f2;
  --footer-heading-text-color: #3a2a2f;
  --footer-body-text-color: #3a2a2f;
  --footer-body-text-color-rgb: 58, 42, 47;
  --footer-accent-color: #ff6128;
  --footer-accent-color-rgb: 255, 97, 40;
  --footer-border: 1px solid var(--border-color);

  --flickity-arrow-color: #bcaea3;
  --product-on-sale-accent: #ee0000;
  --product-on-sale-accent-rgb: 238, 0, 0;
  --product-on-sale-color: #ffffff;
  --product-in-stock-color: #008a00;
  --product-low-stock-color: #f71b1b;
  --product-sold-out-color: #d1d1d4;
  --product-custom-label-1-background: #ff6128;
  --product-custom-label-1-color: #ffffff;
  --product-custom-label-2-background: #a95ebe;
  --product-custom-label-2-color: #ffffff;
  --product-review-star-color: #ffb647;

  --mobile-container-gutter: 20px;
  --desktop-container-gutter: 40px;
}

@font-face {
  font-family: poppinsBold;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppinsSemiBold;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: mulish;
  src: url(assets/fonts/Mulish-VariableFont_wght.ttf);
}
