@import url("fonts.css");

/* Updated Desktop Menu (April 2024) */
.middle-container {
  justify-content: flex-start;
}

.nav-container-desktop {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 80px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px 24px 40px;
  gap: 40px;
  border-bottom: 1px solid #e1e7ed;
}

.desktop-nav-spacer {
  width: 100%;
  height: 80px;
  background-color: #ffffff;
}

.mobile-nav-spacer {
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  display: none;
}

.nav-btn-dark {
  list-style-type: none;
}

.nav-btn-container {
  display: flex;
  gap: 16px;
}

.nav-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  border-radius: 100px;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  font-family: mulish;
  font-weight: 500;
}

.sign-up-btn {
  border: 1px solid #24313e;
}

.log-in-btn,
.recipe-btn {
  background: #24313e;
  color: #ffffff;
}

.recipe-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: fit-content;
  padding: 12px 16px;
  border-radius: 100px;
  gap: 8px;
}

.new-recipe-btn-icon {
  height: 15px;
  width: 15px;
}

.log-out-btn-icon {
  height: 15px;
  width: 16.88px;
}

.logout-btn {
  border: none;
}

.btn-icon {
  height: 20px;
}

.log-in-icon {
  width: 16.27px;
  height: 15.64px;
}

.sign-up-icon {
  width: 18.75px;
  height: 13.75px;
}

.logout-btn:hover {
  font-weight: 700;
}

.left-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  gap: 40px;
}

.logo {
  display: block;
}

.logo-img {
  width: 200px;
  height: auto;
}

.nav-side-menu {
  display: flex;
  align-items: center;
  gap: 24px;
  margin: 0;
  padding: 0;
}

.nav-items {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  cursor: pointer;
}

.nav-links {
  color: black;
  text-decoration: none;
  transition: all 0.3s ease-out;
  font-family: mulish;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.nav-links:hover {
  font-weight: 700;
}

.nav-items:hover {
  text-decoration: underline;
}

/* Updated Mobile Menu (April 2024) */
.nav-container-mobile {
  position: fixed;
  z-index: 99;
  display: none;
  background-color: #ffffff;
}

.nav-container-mobile * {
  font-family: mulish;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}

.nav-mobile-menu {
  background-color: #f6f8fa;
  margin: 0;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;
}

.nav-mobile-menu > * {
  border-bottom: 1px solid #e1e7ed;
  width: 100%;
  background-color: #f6f8fa;
}

.hidden-mobile-nav {
  display: none;
}

.mobile-logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-logo-img {
  max-width: 160px;
  max-height: 32px;
  cursor: pointer;
}

.recipe-link-mobile {
  width: 100%;
  display: flex;
  justify-content: center;
}

.nav-mobile-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e1e7ed;
}

.hamburger-container {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger {
  cursor: pointer;
}

.mobile-nav-right {
  display: flex;
  gap: 12px;
}

@media screen and (max-width: 1024px) {
  .nav-container-mobile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .nav-container-desktop,
  .desktop-nav-spacer {
    display: none;
  }

  .mobile-nav-spacer {
    display: block;
  }

  .nav-mobile-top {
    padding: 16px 40px;
  }

  .nav-links {
    background-color: #f6f8fa;
    padding: 0;
  }

  .nav-links:hover {
    font-weight: 400;
  }

  .nav-items:hover {
    text-decoration: none;
  }

  .nav-items {
    padding: 12px 40px;
  }

  .recipe-link-mobile {
    padding: 24px 40px;
  }

  .recipe-btn {
    width: 100%;
  }

  .nav-btn {
    padding: 0;
  }

  .right-nav {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .nav-container-desktop {
    display: none;
  }

  .mobile-nav-spacer {
    display: block;
  }

  .nav-container-desktop,
  .desktop-nav-spacer {
    display: none;
  }

  .nav-mobile-top {
    padding: 16px 24px;
  }

  .nav-links {
    background-color: #f6f8fa;
  }

  .nav-items {
    padding: 12px 24px;
  }

  .nav-links:hover {
    font-weight: 400;
  }

  .nav-items:hover {
    text-decoration: none;
  }

  .recipe-link-mobile {
    padding: 24px;
  }

  .log-out-btn {
    padding: 0;
  }

  .recipe-link-mobile {
    padding: 24px;
  }

  .right-nav {
    display: none;
  }
}

/* New Recipe Modal (April 2024) */
.new-recipe-modal-container-hidden,
.new-recipe-dropdown-container-hidden {
  display: none;
}

.new-recipe-modal-container {
  display: none;
}

@media screen and (min-width: 1024px) {
  .new-recipe-dropdown-container {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .new-recipe-dropdown {
    background-color: #ffffff;
    position: absolute;
    top: 12px;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 40px 0px #24313e33;
    border-radius: 12px;
    z-index: 10;
  }

  .new-recipe-dropdown * {
    text-decoration: none;
    list-style-type: none;
    color: #24313e;
  }

  .new-recipe-dropdown-item,
  .new-recipe-dropdown-title {
    width: 100%;
    cursor: pointer;
  }

  .new-recipe-dropdown-item {
    border-top: 1px solid #e1e7ed;
  }

  .new-recipe-dropdown-item:hover {
    background-color: #f6f8fa;
    border-radius: 12px;
  }

  .recipe-dropdown-text,
  .recipe-dropdown-bold {
    padding: 12px 24px;
  }

  .recipe-dropdown-text {
    font-family: mulish;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    gap: 4px;
  }

  .recipe-dropdown-bold {
    font-family: mulish;
    font-size: 14px;
    line-height: 24px;
    background-color: #b0afb0;
    text-align: left;
    font-weight: 700;
  }
}

@media screen and (max-width: 1024px) {
  .new-recipe-modal-container {
    position: fixed;
    width: calc(100% - 48px);
    margin: 0 40px;
    padding: 0;
    top: 188px;
    left: -56px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .new-recipe-modal {
    width: 100%;
    background-color: #ffffff;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 40px 0px #24313e33;
    margin: 40px;
    border-radius: 12px;
    z-index: 10;
  }

  .new-recipe-modal-item,
  .new-recipe-modal-title {
    width: 100%;
    cursor: pointer;
  }

  .new-recipe-modal-item {
    border-top: 1px solid #e1e7ed;
  }

  .recipe-modal-text,
  .recipe-modal-bold {
    padding: 12px 24px;
  }

  .new-recipe-modal-item:hover {
    background-color: #f6f8fa;
    border-radius: 12px;
  }

  .new-recipe-modal * {
    text-decoration: none;
    list-style-type: none;
    color: #24313e;
  }

  .recipe-modal-text {
    font-family: mulish;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    gap: 4px;
  }

  .recipe-modal-bold {
    font-family: mulish;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
}

@media screen and (max-width: 768px) {
  .new-recipe-modal-container {
    margin: 0 40px;
    width: calc(100% - 48px);
    padding: 0;
  }
}

/* FEEDBACK MODAL */

.feedback-modal-textarea {
  padding: 24px 16px 16px 16px;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #e1e7ed;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.feedback-modal-input-group {
  position: relative;
}

.feedback-modal-label {
  position: absolute;
  top: -12px;
  left: 16px;
  background-color: #ffffff;
  padding: 0px 4px;
  gap: 10px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #6a727b;
}

.modal-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  text-align: left;
}

.info-modal-link {
  cursor: pointer;
}

.info-modal-body-header {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.info-modal-list {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #6a727b;
}

.info-modal-list li {
  list-style-type: disc;
}

.info-modal-content {
  gap: 16px;
}

.info-modal-footer {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.info-modal-link {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  text-decoration: underline;
}
