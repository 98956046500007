@font-face {
  font-family: poppinsBold;
  src: url(assets/fonts/Poppins-Bold.ttf);
}

@font-face {
  font-family: poppinsSemiBold;
  src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: mulish;
  src: url(assets/fonts/Mulish-VariableFont_wght.ttf);
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

/* HERO SECTION (APRIL 2024) */

.page_container_full {
  width: 100%;
  min-height: 100vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  background-color: #ffffff;
}

.hero-area {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
}

.detail-main-text {
  font-family: poppinsBold;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: #ffffff;
}

.detail-img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 24px;
  background: linear-gradient(
      0deg,
      rgba(36, 49, 62, 0.3),
      rgba(36, 49, 62, 0.3)
    ),
    linear-gradient(179.73deg, rgba(36, 49, 62, 0) 0.24%, #24313e 99.76%);
}

.detail-img-container {
  position: relative;
  width: 100%;
  height: 392px;
}

.full_image_view {
  width: 100%;
  height: 392px;
  object-fit: cover;
  border-radius: 24px;
}


.detail-hero-content {
  position: absolute; /* or as you had it */
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  /* Remove or override margin-top: auto; margin-bottom: 0; justify-content: flex-end; */
}

.detail-hero-top,
.detail-hero-bottom {
  display: flex;
  flex-direction: column;
  gap: 16px; /* spacing between items in top/bottom containers */
}

h2 {
  margin-bottom: 0;
}

.detail-hero-btn-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  height: 48px;
}

.detail-hero-btn {
  color: #ffffff;
  background: #24313e80;
  cursor: pointer;
  font-family: mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
}

.detail-hero-btn:hover {
  background: #ffffff;
  color: #24313e;
}

.detail-hero-actions-btn,
.detail-hero-actions-btn-active {
  align-items: center;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 100px;
  gap: 8px;
  width: fit-content;
  padding: 12px 24px;
}

.detail-hero-actions-btn {
  color: #ffffff;
  background: #24313e80;
}

.detail-hero-actions-btn-active {
  color: #24313e;
  background: #ffffff;
}

.detail-share-btn {
  /*width: 121px;*/
  border-radius: 100px;
  border: 1px solid #ffffff;
}

.detail-share-icon {
  width: 20px;
  height: 20px;
}

.detail-badge-container {
  display: flex;
  gap: 16px;
}

.detail-hero-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: 32px;
  padding: 8px 16px;
  gap: 4px;
  border-radius: 8px;
  background: #ffffff;
  font-family: mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.detail-hero-badge::first-letter {
  text-transform: capitalize;
}

.detail-content-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px;
}

.detail-left-container,
.detail-right-container {
  display: flex;
  flex-direction: column;
  background: #f6f8fa;
  padding: 40px;
  gap: 40px;
  border-radius: 24px;
}

.nutritional-analysis-container-desktop {
  display: flex;
  flex-direction: column;
  background: #f6f8fa;
  padding: 40px;
  gap: 40px;
  border-radius: 24px;
}

.detail-left-container {
  gap: 40px;
}

.detail-right-container {
  height: fit-content;
  display: none;
  flex-direction: column;
}

.detail-left-title-container,
.detail-right-title-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 24px 0px;
  gap: 4px;
  border-bottom: 1px solid #e1e7ed;
}

.detail-container-title {
  color: #24313e;
  font-family: poppinsBold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.detail-ai-badge {
  display: flex;
  height: 32px;
  width: fit-content;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 100px;
  background: #ffffff;
  font-family: mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  color: #24313e;
  white-space: nowrap;
  cursor: pointer;
}

.hero-ai-badge {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  padding: 5px 12px;
  gap: 10px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffffb2;
}

.detail-left-body-text,
.detail-right-body-text {
  font-family: mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

.detail-left-body-text h3 {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin-bottom: 16px;
}

.detail-left-body-text ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.detail-left-body-text__checkbox {
  gap: 12px;
  margin-bottom: 40px;
}

ul.detail-right-body-text {
  list-style-type: none;
  padding: 0;
}

.detail-right-body-text li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px;
}

.detail-right-body-text li span {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.detail-right-body-text li span:first-child {
  color: #6a727b;
}

.detail-footer {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  border-top: 1px solid #e1e7ed;
}

.detail-footer-container {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

.detail-footer-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  font-family: mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;
}

.detail-footer-calendar,
.detail-footer-eye {
  width: 16px;
  height: 16px;
}

.detail-right-footer-container {
  display: none;
}

.detail-hero-actions-btn {
  display: none;
}

.detail-hero-btn-container-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .full_image_view {
    width: 100%;
    height: auto;
    aspect-ratio: 688/330;
  }

  .detail-img-container {
    width: 100%;
    height: auto;
    aspect-ratio: 688/330;
  }

  .detail-hero-btn-container-mobile {
    display: none;
  }

  .detail-main-text {
    font-family: poppinsBold;
    font-size: 40px;
    font-weight: 600;
    line-height: 48px;
    text-align: left;
    color: #ffffff;
    padding-bottom: 0;
  }

  .nutritional-analysis-container-desktop {
    display: none;
  }

  .hero-area {
    grid-template-columns: 1fr;
  }

  .detail-right-container {
    display: flex;
  }
}

@media screen and (max-width: 768px) {
  .page_container_full {
    padding: 24px;
    gap: 24px;
  }

  .full_image_view {
    width: 100%;
    height: auto;
    aspect-ratio: 342/220;
  }

  .detail-img-container {
    width: 100%;
    height: auto;
    aspect-ratio: 342/220;
  }

  .detail-content-container {
    gap: 24px;
  }

  .detail-hero-content {
    width: 100%;
  }

  .detail-left-container,
  .detail-right-container {
    background: #ffffff;
    padding: 0px;
    gap: 24px;
  }

  .detail-hero-actions-btn,
  .detail-hero-actions-btn-active {
    display: flex;
    cursor: pointer;
  }

  .detail-hero-btn-container {
    display: none;
  }

  .detail-hero-btn-container-mobile {
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    position: absolute;
    top: calc(98%);
    display: flex;
    border-radius: 12px;
    box-shadow: 0px 0px 40px 0px #24313e33;
    z-index: 100;
  }

  .detail-hero-btn-mobile {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #24313e;
    width: 100%;
    height: 48px;
    padding: 12px 24px 12px 16px;
    gap: 4px;
    border-bottom: 1px solid #e1e7ed;
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #24313e;
    cursor: pointer;
  }

  .detail-btn-no-border {
    border: none;
  }

  .detail-icon-mobile {
    width: 20px;
  }

  .detail-left-footer-container {
    display: none;
  }

  .detail-right-footer-container {
    display: block;
  }

  .detail-hero-badge {
    display: none;
  }

  .detail-ai-badge {
    background: #f6f8fa;
  }

  .hero-ai-badge {
    display: none;
  }

  .detail-hero-content {
    padding: 24px;
  }

  .detail-main-text {
    font-family: poppinsSemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
}

/* VARIANT MODAL */

.variant-modal-button,
.variant-modal-button-selected {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48;
  padding: 12px 16px;
  gap: 8px;
  border-radius: 8px;
  background: #f6f8fa;

  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  cursor: pointer;
}

.variant-modal-button {
  border: 1px solid #e1e7ed;
}

.variant-modal-button-selected {
  border: 1px solid #24313e;
}

.variant-modal-btn-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.variant-modal-btn,
.share-modal-btn,
.share-modal-link-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 100px;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.variant-modal-btn-dark,
.share-modal-btn-dark,
.share-modal-link-btn {
  background: #24313e;
  color: #ffffff;
  border: none;
}

.variant-modal-btn-light {
  background-color: #ffffff;
  border: 1px solid #24313e;
  color: #24313e;
}

.variant-modal-footer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 0px 0px 0px;
  gap: 16px;
  border-top: 1px solid #e1e7ed;
}

@media screen and (max-width: 768px) {
  .variant-modal-footer {
    justify-content: center;
  }
}

.share-modal-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  padding: 0 0 24px 0;
  border-bottom: 1px solid #e1e7ed;
}

.share-modal-body-header {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.share-modal-link-container {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  padding: 24px 12px;
  gap: 18px;
  border-radius: 11px;
  background: #f6f8fa;
  width: 100%;
}

.share-modal-link-text {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #24313e;
  overflow-wrap: anywhere;
}

.share-modal-body-nonbold {
  font-family: Mulish;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}

.share-modal-text-input {
  width: 100%;
  height: 48px;
  padding: 12px 16px;
  gap: 12px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e1e7ed;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #24313e;
}

.share-modal-text-input::placeholder {
  color: #a3a8ae;
}

.share-modal-input-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 18px;
  width: 100%;
  padding: 24px 12px;
  gap: 18px;
  border-radius: 11px;
  background: #f6f8fa;
}

.share-modal-recipients-container {
  width: 100%;
  height: fit-content;
  padding: 12px 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  border-radius: 11px;
  background: #f6f8fa;
}

.share-modal-recipient {
  color: #24313e;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  background-color: #f6f8fa;
  border: none;
}

.share-modal-recipient:hover {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .share-modal-input-container {
    flex-direction: column;
  }

  .share-modal-text-input {
    width: 100%;
  }

  .share-modal-send-btn {
    width: fit-content;
  }

  .share-modal-link-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.share-modal-loading {
  height: 464px;
}

.share-modal-loading-container {
  height: 336px;
}

.share-modal-loading-overlay {
  height: 100%;
}

.detail-recipe-source {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #6a727b;
  width: fit-content;
  display: flex;
  gap: 8px;
}

.detail-recipe-source-link {
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #24313e;
  text-decoration: underline;
}

.detail-recipe-source-link a {
  color: #24313e;
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
}

.detail-footer-recipe-info {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.detail-left-body-text__directions {
  gap: 12px;
}
