/* Updated Desktop View (April 2024) */

@import url("fonts.css");


.create-from-image-container {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.create-from-image-preview {
  max-width: 100%;
  max-height: 500px;
  width: auto;
  height: auto;
  display: block;
  margin: 16px auto;
  object-fit: contain;
}

.app-container {
  min-height: 100vh;
  max-height: auto;
  background-color: #ffffff;
}

.main-container {
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 40px;
  width: 899px;
  margin: 0 auto;
}

.create-recipe-btn {
  background-color: #24313e;
  color: #ffffff;
  text-align: center;
  padding: 12px 24px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.new-recipe-disclaimer {
  font-family: mulish;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
}

.textarea-char-count {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  font-family: mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: right;
}

.form-container {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
}

.recipe-hero-img {
  width: 100%;
  aspect-ratio: 899/240;
  object-fit: cover;
  border-radius: 16px;
  z-index: -1;
}

.recipe-hero-img-container {
  position: relative;
  width: 100%;
}

.recipe-hero-content {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 40px;
}

.recipe-hero-main-text {
  font-family: poppinsBold;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: white;
}

.recipe-hero-subtext {
  font-family: mulish;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: white;
}

.line-break {
  display: none;
}

.recipe-hero-img-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
      0deg,
      rgba(36, 49, 62, 0.05),
      rgba(36, 49, 62, 0.05)
    ),
    linear-gradient(
      269.71deg,
      rgba(36, 49, 62, 0) 0.25%,
      rgba(36, 49, 62, 0.5) 99.76%
    );
  border-radius: 16px;
}

.form-container {
  background-color: #f6f8fa;
  padding: 40px;
  gap: 40px;
}

.textarea-char-count {
  margin-top: -32px;
}

.text-area {
  padding: 12px 16px;
  font-family: mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 12px;
  border: 1px solid #e1e7ed;
  color: #24313e;
}

.create-recipe-btn {
  width: fit-content;
}

.create-recipe-btn-text {
  font-family: Mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

/* Updated Tablet View (April 2024) */

@media screen and (max-width: 1024px) {
  .main-container {
    width: 100%;
  }

  .recipe-hero-img {
    aspect-ratio: 688/240;
  }

  .recipe-hero-img-container {
    position: relative;
    width: 100%;
  }

  .recipe-hero-main-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }

  .recipe-hero-subtext {
    font-size: 14px;
  }

  .create-recipe-btn {
    width: fit-content;
  }
}

/* Updated Mobile View (April 2024) */

@media screen and (max-width: 768px) {
  .main-container {
    padding: 24px;
    gap: 24px;
  }

  .recipe-hero-img {
    aspect-ratio: 342/160;
  }

  .recipe-hero-content {
    padding: 0 24px;
  }

  .recipe-hero-main-text {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
  }

  .recipe-hero-subtext {
    font-size: 12px;
    line-height: 16px;
  }

  .line-break {
    display: block;
  }

  .form-container {
    background-color: white;
    padding: 0;
    gap: 24px;
  }

  .textarea-char-count {
    margin-top: -20px;
  }

  .new-recipe-disclaimer {
    font-size: 10px;
  }

  .create-recipe-btn {
    width: 100%;
  }
}

/* Upload from Image */

.create-recipe-btn-disabled {
  background-color: #e1e7ed;
  color: #6a727b;
  text-align: center;
  padding: 12px 24px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  gap: 8px;
  width: fit-content;
  pointer-events: none;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #24313e;
  margin-right: auto;
  height: 48px;
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 100px;
  cursor: pointer;
  font-family: mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
}

.no-border {
  border: none;
  padding: 0;
  height: auto;
  margin-bottom: 16px;
}

.new-image {
  height: 240px;
  width: auto;
  border-radius: 12px;
}

.flex-center {
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .new-image {
    height: 160px;
    width: auto;
  }

  .create-recipe-btn-disabled {
    width: 100%;
  }

  .custom-file-upload {
    width: 100%;
  }

  .left-alignment {
    justify-content: start;
  }
}

/* Upload from URL */

.recipe-text-input {
  width: 100% !important;
  padding: 12px 16px 12px 16px;
  border-radius: 8px !important;
  border: 1px solid #e1e7ed;
  font-family: mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.recipe-input-div {
  position: relative;
}

.input-img {
  position: absolute;
  top: 17px;
  right: 17px;
  width: 16px;
  height: 16px;
}

/* Upload From Scratch & Edit Recipe Pages */

.input-line-one {
  display: grid;
  justify-content: start;
  grid-gap: 24px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}

.input-line-one input,
.input-line-one select {
  width: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.input-label {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.category-select-input {
  width: 100%;
  cursor: pointer;
  border-radius: 8px;
  height: 48px;
  padding: 12px 16px;
  border: 1px solid #e1e7ed;
  font-family: mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.category-option-input {
  width: 100%;
}

.input-label {
  font-family: mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.edit-textarea {
  height: 320px;
  width: 100%;
  padding: 12px 16px;
  font-family: mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  border-radius: 12px !important;
  border: 1px solid #e1e7ed;
  color: #24313e;
  overflow-y: scroll;
}

.edit-visibility-disclaimer {
  color: #6a727b;
  font-family: mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.edit-visibility-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 24px;
}

.edit-nutritional-container {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 4px;
}

.edit-nutritional-title {
  color: #24313e;
  font-family: mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.edit-nutritional-text {
  color: #6a727b;
  font-family: Mulish;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.cancel-recipe {
  display: flex;
  flex-direction: row;
  width: fit-content;
  padding: 12px 24px;
  gap: 8px;
  border-radius: 100px;
  border: 1px solid #24313e;
  background-color: none;
  color: #24313e;
  font-family: mulish;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
}

.btns-container {
  display: flex;
  width: fit-content;
  gap: 16px;
}

.save-recipe-checkmark {
  width: 15.63px;
  height: auto;
}

.cancel-recipe-x {
  width: 12.5px;
  height: auto;
}

.toggler-icon {
  cursor: pointer;
  height: 24px;
  width: 40px;
}

.visibility-toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  font-family: Mulish;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}

@media screen and (max-width: 768px) {
  .input-line-one {
    display: grid;
    justify-content: start;
    grid-gap: 24px;
    grid-auto-flow: row;
    grid-auto-rows: 1fr;
  }

  .btns-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 24px;
  }

  .cancel-recipe {
    width: 100%;
    justify-content: center;
  }
}
